<template>
  <div class="mt-5">
    <v-text-field
      v-model="searchCustomer"
      class="mb-5"
      append-icon="mdi-magnify"
      label="Search by DIGITAL ID"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="customers"
      :options.sync="pagination"
      :items-per-page-options="pagination.rowsPerPageItems"
      :server-items-length="pagination.totalItems"
      :search="search"
      :loading="loading"
    >
      <!-- <template #[`item.No`]="{ item }">
        {{ index + 1 }}
      </template> -->
      <template #[`item.tel_type`]="{ item }">
        <v-chip small>
          {{ item.tel_type === 'T' ? 'POSTPAID' : 'PREPAID' }}
        </v-chip>
      </template>
      <template #[`item.createTime`]="{ item }">
        {{ item.create_date | formatDate }}
      </template>
      <template #[`item.updateTime`]="{ item }">
        {{ item.last_update | formatDate }}
      </template>
      <template #[`item.action`]="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn icon @click="getHistory(item)" v-on="on">
              <v-icon small color="grey lighten-1"> mdi-calendar-month-outline </v-icon>
            </v-btn>
          </template>
          <span>History</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="openDialogHistory" persistent max-width="650px">
      <v-card>
        <v-card-title>
          <span class="subheadline">Push Message History</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="card-text">
          <v-container>
            <v-row>
              <v-menu
                v-model="dateHistoy"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    dense
                    label="Date Start Job"
                    hint="DD/MM/YYYY format"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                  <!-- <v-text-field
                    v-model="dateFormatted"
                    label="Select history date."
                    prepend-icon="mdi-calendar"
                    readonly
                    @blur="date = parseDate(dateFormatted)"
                    v-bind="attrs"
                    v-on="on"></v-text-field> -->
                </template>
                <v-date-picker v-model="historyDate" @input="dateHistoy = false"></v-date-picker>
              </v-menu>
            </v-row>
          </v-container>
          <v-list-item-group v-for="(history, index) in cusHistory" :key="index">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4 light-blue--text darken-1">
                  {{ history.updateTime | formatDate }}
                </div>
                <v-list-item-title class="subheadline mb-1">
                  {{ history.title }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ history.message }}</v-list-item-subtitle>
                <v-list-item-subtitle class="historyStatus">{{
                  history.status
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <!-- <v-list-item-avatar
                  tile
                  size="80"
                  color="grey"></v-list-item-avatar> -->
            </v-list-item>
            <v-divider />
          </v-list-item-group>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn class="ml-5" depressed color="error" outlined @click="openDialogHistory = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CusomersList',
  data() {
    return {
      loading: false,
      search: '',
      openDialogHistory: false,
      dateHistoy: false,
      historyDate: new Date().toISOString().substr(0, 10),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      searchHistory: {
        date: '',
        ids: '',
      },
      headers: [
        // {
        //   text: 'No.',
        //   align: 'center',
        //   sortable: false,
        //   value: 'No'
        // },
        {
          text: 'DIGITAL ID',
          align: 'start',
          sortable: true,
          value: 'dtac_id',
        },
        {
          text: 'Device',
          align: 'center',
          sortable: true,
          value: 'device',
        },
        {
          text: 'App Version',
          align: 'center',
          sortable: true,
          value: 'app_version_name',
        },
        {
          text: 'Tel Type',
          align: 'center',
          sortable: true,
          value: 'tel_type',
        },
        {
          text: 'Create Date',
          align: 'center',
          sortable: true,
          value: 'createTime',
        },

        {
          text: 'Update Date',
          align: 'center',
          sortable: true,
          value: 'updateTime',
        },

        {
          align: 'start',
          sortable: true,
          value: 'action',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      customers: 'customers/GET_CUSTOMERS_REGISTRATION',
      cusHistory: 'customers/GET_HISTORIES',
    }),

    pagination: {
      get() {
        return this.$store.getters['customers/GET_PAGINATION'];
      },
      set(value) {
        this.$store.commit('customers/SET_PAGINATION', value);
      },
    },

    searchCustomer: {
      set(value) {
        this.$store.commit('customers/SET_SEARCH_DTACID', value);
      },
    },

    // customerHistory: {
    //   get() {
    //     return this.$store.getters['customers/GET_HISTORIES'];
    //   },

    //   set(value) {
    //     this.$store.commit('customers/CUSTOMER_HISTORY_DATE', value);
    //   }
    // },

    computedDateFormatted() {
      return this.formatDate(this.historyDate);
    },
  },

  watch: {
    dateHistoy(val) {
      console.log(val);
      this.dateFormatted = this.formatDate(this.historyDate);

      const dateHistory = new Date(this.historyDate);
      const date = dateHistory.getTime();
      this.searchHistory.date = date;
    },

    searchHistory: {
      async handler() {
        await this.$store.dispatch('customers/GetCustomerHistoriesPerDay', this.searchHistory);
      },
      deep: true,
    },

    pagination: {
      async handler() {
        this.loading = true;
        await this.$store
          .dispatch('customers/GetCustomerRegistration', this.pagination)
          .then(() => {
            this.loading = false;
          });
      },
      deep: true,
    },
  },
  mounted() {
    this.getCustomers();
  },

  methods: {
    async getCustomers() {
      this.loading = true;
      await this.$store
        .dispatch('customers/GetCustomerRegistration', this.pagination)
        .then((res) => {
          console.log(res);
          this.loading = false;
        });
    },

    async getHistory(val) {
      this.openDialogHistory = true;

      const dateHistory = new Date(this.historyDate);
      const date = dateHistory.getTime();

      this.searchHistory.date = date;
      this.searchHistory.ids = val.dtac_id;

      await this.$store.dispatch('customers/GetCustomerHistoriesPerDay', this.searchHistory);
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
  },
};
</script>

<style></style>
